import { Header, Breadcrumb, Menu, MenuItem } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './Financeiro.css';
import { useState } from 'react';
import ViewDebitosPrincipais from './components/ViewDebitosPrincipais';
import ViewDebitosAdministrativos from './components/ViewDebitosAdministrativos';
import ViewArrecadacao from './components/ViewArrecadacao';

function Financeiro() {
  const [activeItem, setActiveItem] = useState('debitos_principais');

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: 'left' }}>
          Painel financeiro
        </Header>
        <Breadcrumb style={{ float: 'right' }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Painel financeiro</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div>
        <Menu pointing>
            <MenuItem
              name='debitos_principais'
              active={activeItem === 'debitos_principais'}
              onClick={() => setActiveItem('debitos_principais')}
            />
            <MenuItem
              name='debitos_administrativos'
              active={activeItem === 'debitos_administrativos'}
              onClick={() => setActiveItem('debitos_administrativos')}
            />
            <MenuItem
              name='arreacadacao'
              active={activeItem === 'arreacadacao'}
              onClick={() => setActiveItem('arreacadacao')}
            />
        </Menu>
        {activeItem === 'debitos_principais' ? <ViewDebitosPrincipais /> : null}
        {activeItem === 'debitos_administrativos' ? <ViewDebitosAdministrativos /> : null}
        {activeItem === 'arreacadacao' ? <ViewArrecadacao /> : null}
      </div>
    </div>
  );
}

export default Financeiro;
