import {
  Button, Menu, Icon, Sidebar,
} from 'semantic-ui-react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { remove } from '../features/Login/loginSlice';
import useAxiosInterceptor from '../hooks/useAxiosInterceptor';

function Layout({ children }) {
  useAxiosInterceptor();

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [,, removeCookie] = useCookies(['name']);
  const navigate = useNavigate();

  const deslogar = () => {
    const options = {
      path: '/',
      sameSite: 'lax',
      secure: true,
      domain: '.sintrabor.org.br',
    };
    removeCookie('authtoken', options);
    dispatch(remove());
  };

  return (
    <>
      <Menu style={{ marginBottom: '0' }} stackable>
        <Menu.Item>
          <Button
            basic
            style={{ boxShadow: 'none', paddingLeft: '5px', paddingRight: '0' }}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="bars" />
          </Button>
        </Menu.Item>
        <img
          alt="logo"
          className="logo-menu"
          src="https://sintrabor.org.br/wp-content/uploads/2020/06/banner-sintrabor.png"
        />
      </Menu>
      <Sidebar.Pushable style={{ height: '90vh' }}>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          vertical
          visible={visible}
          width="thin"
        >
          <Menu.Item as="a" href="/">
            <Icon name="home" />
            Dashboard
          </Menu.Item>
          <Menu.Item onClick={() => navigate('/alterar-senha')}>
            <Icon name="key" />
            Alterar senha
          </Menu.Item>
          <Menu.Item onClick={deslogar}>
            <Icon name="close" />
            Sair
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher style={{ padding: '1rem' }}>{children}</Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
}

export default Layout;
