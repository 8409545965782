import { Button, Dimmer, Grid, Icon, Loader, Segment, Statistic } from "semantic-ui-react";
import useApiPangeo from "../../../hooks/useApiPangeo";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./BarraNumeros.css";

function BarraNumeros() {
  const { data, isLoading, getResumo } = useApiPangeo();
  const navigate = useNavigate();

  useEffect(() => {
    getResumo();
  }, [getResumo]);

  const irParaDenuncias = () => navigate("/denuncias/subregional?status=D,V");

  const irParaAcidentes = () => navigate("/acidentes/subregional?status=D,V");

  const irParaVisitas = () => navigate("/visitas?status=R");

  const irParaSocios = () => navigate("/socios");

  const irParaPedidosDesligamento = () => navigate("/socios/pedidos-desligamento-mes");

  const irParaNegociacoes = () => navigate("/negociacoes");

  const formataPdviPorStatus = (array) => {
    if (!array) return;
    let status_v = 0,
      status_d = 0;

    array.forEach((r) => {
      if (r.status === "V") status_v = parseInt(r.quantidade);
      if (r.status === "D") status_d = parseInt(r.quantidade);
    });
    return status_v + status_d;
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Grid.Row stretched className="barra-numeros">
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Segment as={Button} onClick={irParaSocios} style={{ textAlign: "center"}}>
            <Statistic color='blue'>
              <Statistic.Label>Sócios ativos politicamente</Statistic.Label>
              <Statistic.Value style={{lineHeight: "0.35em", marginTop: "0.3em"}}>
                {new Intl.NumberFormat().format(data.resumo?.socios_ativos_politicamente || 0)}
                <br/>
                <span style={{fontSize: '11px'}}>
                    + {new Intl.NumberFormat().format(data.resumo?.socios_ativos_nao_politicamente || 0)} aposentados e pensionistas
                </span>
              </Statistic.Value>
            </Statistic>
          </Segment>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={3}>
          <Segment as={Button} onClick={irParaPedidosDesligamento} style={{ textAlign: "center" }}>
            <Statistic color='blue'>
              <Statistic.Label>Pedidos de desligamento no mês</Statistic.Label>
              <Statistic.Value>
                {data.resumo?.pedidos_desligamento_mes}
              </Statistic.Value>
            </Statistic>
          </Segment>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={9}>
          <Segment>
            <div className="ui statistics barra-numeros">
            <Statistic color='basic' as={Button} onClick={irParaNegociacoes} >
                <Statistic.Value>
                  <Icon name="pencil" /> {data.resumo?.negociacao_andamento}
                </Statistic.Value>
                <Statistic.Label>Negociações andamento</Statistic.Label>
              </Statistic>
              <Statistic color='basic' as={Button} onClick={irParaVisitas} >
                <Statistic.Value>
                  <Icon name="map marker alternate" /> {data.resumo?.visitas_realizadas_mes}
                </Statistic.Value>
                <Statistic.Label>Visitas no mês</Statistic.Label>
              </Statistic>
              <Statistic color='basic' as={Button} onClick={irParaAcidentes} >
                <Statistic.Value>
                  <Icon name="ambulance" /> {formataPdviPorStatus(data.resumo?.acidentes_nao_encerrados)}
                </Statistic.Value>
                <Statistic.Label>Acidentes pendentes</Statistic.Label>
              </Statistic>
              <Statistic color='basic' as={Button} onClick={irParaDenuncias} >
                <Statistic.Value>
                  <Icon name="bullhorn" /> {formataPdviPorStatus(data.resumo?.denuncias_nao_encerradas)}
                </Statistic.Value>
                <Statistic.Label>Denúncias pendentes</Statistic.Label>
              </Statistic>
            </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default BarraNumeros;
