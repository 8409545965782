import { useEffect, useState } from "react";
import Moment from "react-moment";
import {
  Accordion,
  Dimmer,
  Loader,
  Pagination,
  Segment,
  Input,
} from "semantic-ui-react";
import StatusPedivisiText from "../../components/StatusPedivisiText";
import useApiPangeo from "../../hooks/useApiPangeo";

function ListaPedidoVisita({ tipo, statusList, subregi }) {
  const { data, isLoading, getPedivisiList } = useApiPangeo();
  const [page, setPage] = useState(1);
  const [filtroNomeEmpresa, setFiltroNomeEmpresa] = useState(null);

  useEffect(() => {
    const [regi, subr] = subregi.split(".");
    let filtros = { tipo: tipo, regi: regi, subregi: subr };

    statusList.split(",").forEach((status, key) => {
      filtros[`status[${key}]`] = status;
    });

    if (filtroNomeEmpresa && filtroNomeEmpresa.length > 0) {
      filtros["nomeEmpresa"] = filtroNomeEmpresa;
    }

    filtros["limit"] = 10;
    filtros["page"] = page;

    getPedivisiList(filtros);
  }, [getPedivisiList, page, filtroNomeEmpresa, tipo, statusList, subregi]);

  const formatPanels = (pedivisis) =>
    pedivisis?.map((pedivisi, k) => ({
      key: `panel-${pedivisi?.pdvi || k}`,
      title: {
        content: (
          <>
            <span style={{ color: "#333" }}>
              {pedivisi?._embedded?.pejuUnid?.nome}
            </span>

            <div
              className="clearfix"
              style={{
                paddingBottom: "1rem",
                paddingTop: "10px",
                fontSize: "0.9rem",
                fontWeight: "500",
                color: "#333",
              }}
            >
              <div style={{ float: "left", width: "60%" }}>
                Motivo: {pedivisi?._embedded?.tppdvi?.descricao}
              </div>
              <div style={{ float: "right", width: "40%", textAlign: "right" }}>
                <StatusPedivisiText
                  statusChar={pedivisi?.status}
                  asLabel={true}
                />
              </div>
            </div>
          </>
        ),
      },
      content: {
        content: (
          <>
            <dl style={{ margin: "0" }}>
              <dt>Data</dt>
              <dd>
                <Moment
                  format="DD/MM/YYYY"
                  date={pedivisi?.dtdenuncia?.date || pedivisi?.dtAcid?.date}
                />
              </dd>
              <dt>Denunciante</dt>
              <dd>{pedivisi?.denunciante || pedivisi?.outroEnvolvido}</dd>
              <dt>Descrição</dt>
              <dd style={{ whiteSpace: "break-spaces" }}>
                {pedivisi?.descricao}
              </dd>
              {pedivisi?.status === "E" && <dt>Encerramento</dt>}
              {pedivisi?.status === "E" && (
                <dd>
                  {!pedivisi?.usuarioEncerramento && "- MOTIVO NÃO INFORMADO -"}

                  {pedivisi?.usuarioEncerramento &&
                    pedivisi?.motivoEncerramento}

                  {pedivisi?.usuarioEncerramento &&
                    " Usuário: " + pedivisi?.usuarioEncerramento}

                  {pedivisi?.dataHoraEncerramento?.date && (
                    <span>
                      {" "}
                      em{" "}
                      <Moment
                        format="DD/MM/YYYY"
                        date={pedivisi?.dataHoraEncerramento?.date}
                      />
                    </span>
                  )}
                </dd>
              )}
            </dl>
          </>
        ),
      },
    }));

  return (
    <div>
      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <div className="clearfix" style={{ marginBottom: "10px" }}>
          <h3 style={{ float: 'left' }}>{tipo === "A" ? "Acidentes" : "Denúncias"} da regional</h3>
          <div style={{ float: 'right' }}>
            <Input icon="search" placeholder="Buscar empresa..." onChange={(e) => setFiltroNomeEmpresa(e.target.value)} />
          </div>
        </div>
        <Accordion
          style={{ width: "100%" }}
          styled
          panels={formatPanels(data?._embedded?.pedivisi)}
        />
        <Pagination
          activePage={page}
          style={{ marginTop: "1em" }}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          totalPages={"page_count" in data ? data["page_count"] : 0}
        />
      </Segment>
    </div>
  );
}

export default ListaPedidoVisita;
