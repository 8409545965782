import Plot from "react-plotly.js";
import { Dimmer, Loader, Menu } from "semantic-ui-react";
import * as localeDictionary from 'plotly.js-locales/pt-br.js'

import useApiPangeo from "../../../hooks/useApiPangeo";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function GraficoMovimentacaoSocios() {
  const { data, isLoading, getPessfisiMovimentacao, getPessfisiMovimentacaoPorRegional } = useApiPangeo();
  const [activeItem, setActiveItem] = useState(0);
  const [titulo, setTitulo] = useState("MOVIMENTAÇÃO SÓCIOS");
  const usuario = useSelector((state) => state.login.usuario);

  useEffect(() => {
    switch (activeItem) {
      case 0:
        setTitulo("MOVIMENTAÇÃO SÓCIOS");
        getPessfisiMovimentacao();
        break;
      case 3:
      case 4:
      case 6:
        const regionais = {
          3: "GUARULHOS/SP",
          6: "SAO MIGUEL",
          4: "STO ANDRE",
        };
        setTitulo(`MOVIMENTAÇÃO SÓCIOS - ${regionais[activeItem]}`)
        getPessfisiMovimentacaoPorRegional(activeItem);
        break;
      default:
        break;
    }
  }, [getPessfisiMovimentacao, getPessfisiMovimentacaoPorRegional, activeItem]);

  const getArrayValoresInData = (campo) => {
    if (!Array.isArray(data)) return [];
    return data?.map((r) => `${r[campo]}`.trim());
  };

  const selecionaRegional = () => {
    return usuario?.demais_dados?.recursos
      .filter((recurso) => recurso === "intranetBgp.*").length > 0
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Plot
        data={[
          {
            type: "line",
            y: getArrayValoresInData("novos"),
            x: getArrayValoresInData("referencia"),
            marker: {
              color: "rgb(55, 83, 109)",
            },
            text: getArrayValoresInData("novos")?.map(String),
            textposition: "top",
            name: "Novos",
            mode: "lines+markers+text",
          },
          {
            type: "line",
            y: getArrayValoresInData("cancelados"),
            x: getArrayValoresInData("referencia"),
            marker: {
              color: "rgb(168, 29, 40)",
            },
            text: getArrayValoresInData("cancelados")?.map(String),
            textposition: "bottom",
            name: "Desligados",
            mode: "lines+markers+text",
          },
        ]}
        config={{
          modeBarButtonsToAdd: [],
          modeBarButtonsToRemove: [
            "pan2d",
            "select2d",
            "lasso2d",
            "resetScale2d",
            "zoomOut",
            "zoom",
            "zoomIn",
            "autoScale",
            "sendDataToCloud",
            "resetViewMapbox"
          ],
          responsive: true,
          staticPlot: true,
          locales: { 'pt-BR': localeDictionary },
          locale: 'pt-BR'
        }}
        layout={{
          title: {
            text: `<b>${titulo}</b>`,
            font: {
              family: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              size: 17,
            },
          },
          font: {
            size: 10,
          },
          margin: { t: 40, b: 30, l: 40, r: 20 },
          legend: {
            orientation: "h",
          },
        }}
        style={{ width: "100%", height: "320px" }}
      />
      {selecionaRegional() ? <Menu size="mini">
        <Menu.Item
          name="TOTAL"
          active={activeItem === 0}
          onClick={() => setActiveItem(0)}
        />
        <Menu.Item
          name="GUARULHOS/SP"
          active={activeItem === 3}
          onClick={() => setActiveItem(3)}
        />
        <Menu.Item
          name="SAO MIGUEL"
          active={activeItem === 6}
          onClick={() => setActiveItem(6)}
        />
        <Menu.Item
          name="STO ANDRE"
          active={activeItem === 4}
          onClick={() => setActiveItem(4)}
        />
      </Menu> : null}
    </>
  );
}

export default GraficoMovimentacaoSocios;
