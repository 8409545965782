/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  usuario: {},
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    update: (state, action) => {
      const { token, usuario } = action.payload;
      state.usuario = usuario;
      state.token = token;
    },
    remove: (state) => {
      state.usuario = {};
      state.token = null;
    },
  },
});

export const { update, remove } = loginSlice.actions;

export default loginSlice.reducer;
