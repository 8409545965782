import Moment from 'react-moment';
import { Button, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import _ from 'lodash';

function CRTabela({ crs }) {
  const [dadosAtuais, setDadosAtuais] = useState({ nome: '', listaCrs: [] });

  const totaisPorTipo = () => {
    let tipos = {};
    crs.forEach((cr) => {
      let subtipo = cr.receita.subtipo;
      if (!Object.keys(tipos).includes(subtipo)) {
        tipos[subtipo] = [];
      }
      tipos[subtipo].push(cr);
    });
    return tipos;
  }

  const exibirAgrupadoPorEmpresa = (listaCrs, key) => {
    setDadosAtuais({ nome: key, listaCrs: listaCrs });
  };

  return (
    <>
      <Grid>
        <Grid.Row stretched>
          <Grid.Column mobile={16} computer={6}>
            <Segment>
              <Table unstackable celled collapsing className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Tipo de receita</Table.HeaderCell>
                    <Table.HeaderCell>Quantidade</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {Object.entries(totaisPorTipo() || {}).map(([key, totalPorTipo], i) => {
                    return <Table.Row key={i}>
                      <Table.Cell data-label="Tipo de receita">
                        <Header as="h4" image>
                          <Header.Content>
                            <Button
                              icon
                              circular
                              onClick={() => exibirAgrupadoPorEmpresa(totalPorTipo, key)}
                              positive={
                                key === dadosAtuais.nome
                              }
                            >
                              <Icon name={key === dadosAtuais.nome ? "check" : "plus"} />
                            </Button>
                            {key}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell data-label="Quantidade">
                        {totalPorTipo?.length}
                      </Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>
                      Total de débitos:{" "}
                      {new Intl.NumberFormat().format(crs?.length | 0)}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} computer={10}>
            {! dadosAtuais.nome ? null : <Segment>
              <h3>Débitos com o tipo {dadosAtuais.nome}</h3>
              <Table unstackable celled collapsing className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Empresa</Table.HeaderCell>
                    <Table.HeaderCell>Receita</Table.HeaderCell>
                    <Table.HeaderCell>Vencimento</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {dadosAtuais.listaCrs.map((cr) => {
                    return <Table.Row key={`${cr?.id}-${cr?.parc}`}>
                      <Table.Cell data-label="Empresa">
                        <Header as="h4" image>
                          <Header.Content>
                            ({cr?.empresa.id}) {cr?.empresa.nome}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell data-label="Receita">
                        {cr?.receita.descricao} - {cr?.referencia}
                      </Table.Cell>
                      <Table.Cell data-label="Vencimento">
                        <Moment format="DD/MM/YYYY" date={cr?.dtVencimento?.date} />
                      </Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>
            </Segment>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

CRTabela.propTypes = {
  crs: PropTypes.array.isRequired
};

export default CRTabela;
