import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import loginReducer from '../features/Login/loginSlice';

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
};

const reducers = {
  login: loginReducer,
};

const rootReducer = persistCombineReducers(persistConfig, reducers);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
});

const persistor = persistStore(store, {});

export { store, rootReducer, persistor };
