import { useState } from 'react';
import {
  Button, Icon, Modal, Table,
} from 'semantic-ui-react';
import ValorPlr from './ValorPlr';
import Moment from 'react-moment';

function ButtonComponent({ parcelas, onClick }) {
  const qtd = parcelas?.length || 0;

  if (qtd === 0) return <Button disabled>nenhuma parcela</Button>;
  return (
    <Button onClick={onClick}>
      {qtd}
      {' '}
      parcelas
    </Button>
  );
}

function ParcelasModal({ parcelas }) {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<ButtonComponent parcelas={parcelas} />}
    >
      <Modal.Header>Parcelas</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          <Table unstackable celled collapsing className="dnxTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data pagamento</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell>Taxa negocial</Table.HeaderCell>
                <Table.HeaderCell>Tem metas?</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {parcelas?.map((parcela) => (
                <Table.Row key={parcela.seq}>
                  <Table.Cell data-label="Data pagamento">
                    <Moment format="DD/MM/YYYY" date={parcela?.vencimento?.date} />
                  </Table.Cell>
                  <Table.Cell data-label="Valor">
                    <ValorPlr acordo={parcela} />
                  </Table.Cell>
                  <Table.Cell data-label="Taxa negocial">
                    <Moment format="DD/MM/YYYY" date={parcela?.vencimentoNegocial?.date} />
                  </Table.Cell>
                  <Table.Cell data-label="Tem metas?">
                    {parcela?.temMetas ? 'SIM' : 'NÃO'}
                    {parcela?.descricaoMeta}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Fechar
          {' '}
          <Icon name="chevron right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ParcelasModal;
