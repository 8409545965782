import { Header, Breadcrumb, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './Manuais.css';

function Manuais() {
  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: 'left' }}>
          Manuais e procedimentos
        </Header>
        <Breadcrumb style={{ float: 'right' }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Manuais</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div>
        <h4>Manuais dos sistemas</h4>

        <a href="/documentos/manual_pangeo.pdf" target="_blank" className="manuais-link">
          <Icon name="file pdf outline" />
          <br />
          Pangeo
        </a>

        <h4>Manuais de procedimentos internos</h4>

        <a href="/documentos/acordo_coletivo_procedimento.pdf" target="_blank" className="manuais-link">
          <Icon name="file pdf outline" />
          <br />
          Acordo Coletivo
        </a>
        <a href="/documentos/delegado_sindical_procedimento.pdf" target="_blank" className="manuais-link">
          <Icon name="file pdf outline" />
          <br />
          Delegado Sindical
        </a>

        <h4>Manuais de outros documentos</h4>

        <a href="/documentos/delegado_sindical_regras.pdf" target="_blank" className="manuais-link">
          <Icon name="file pdf outline" />
          <br />
          Delegado Sindical - Palestra Dr Cesar
        </a>
        <a href="/documentos/negociacao_coletiva_reforma_trabalhista_palestra.pdf" target="_blank" className="manuais-link">
          <Icon name="file pdf outline" />
          <br />
          Negociação Coletiva - Palestra Dr Cesar
        </a>
      </div>
    </div>
  );
}

export default Manuais;
