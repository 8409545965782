import Plot from "react-plotly.js";
import { Dimmer, Loader } from "semantic-ui-react";

function GraficoPizza({ keys, values, titulo, isLoading }) {
  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Plot
        data={[
          {
            type: "pie",
            values: values,
            labels: keys,
            marker: {
              colors: ["#4183c4", "	#4142c4", "	#41c4c4", "#83c441"],
            },
            textposition: 'auto',
            textinfo: "percent+value",
          },
        ]}
        config={{
          responsive: true,
          staticPlot: true,
        }}
        useResizeHandler={true}
        style={{ width: "auto", maxWidth: "100%", height: "400px" }}
        layout={{
          title: {
            text: `<b>${titulo}</b>`,
            font: {
              family: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              size: 17,
            },
          },
          showlegend: true,
          legend: {"orientation": "h"},
        }}
      />
    </>
  );
}

export default GraficoPizza;
