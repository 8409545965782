const ValorPlr = ({ acordo }) => {
  
  return (
    <>
      {acordo?.percentual
        ? `${new Intl.NumberFormat().format(acordo?.percentual)}%`
        : <div>
          R$ {new Intl.NumberFormat().format(acordo?.valor)}
          </div>}
    </>
  );
};

export default ValorPlr;
