import { Form, Button, Dimmer, Loader } from "semantic-ui-react";
import useApiPuts from "../../hooks/useApiPuts";
import { useSelector } from "react-redux";
import useForm from "../../hooks/useForm";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const { isLoading, loginAction } = useApiPuts();
  const login = useSelector((state) => state.login);
  const navigate = useNavigate();

  useEffect(() => {
    if (login.token) {
      const jwtPayload = JSON.parse(window.atob(login.token.split('.')[1]));
      if (Date.now() < jwtPayload.exp * 1000) {
        navigate("/home");
      }
    }
  }, [login, navigate]);

  const nextWithValidation = () => {
    loginAction(data);
  };

  const validations = {
    senha: {
      required: {
        value: true,
        message: "Informe sua senha",
      },
    },
    email: {
      required: {
        value: true,
        message: "Informe seu e-mail",
      },
      pattern: {
        value: /^([a-z0-9+_-]+)(\.[a-z0-9+_-]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/i,
        message: "Informe um e-mail válido",
      },
    },
  };

  const { data, handleChange, handleSubmit, handleBlur, errors } = useForm(
    validations,
    {},
    nextWithValidation
  );

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Form className="form-login" onSubmit={handleSubmit}>
        <div>
        <img
          alt="logo"
          style={{ width: "70px", margin: "10px auto", display: "block" }}
          src="/logo192.png"
        />

        </div>
        <h1>INTRANET SINTRABOR</h1>
        <Form.Input
          defaultValue={data.email}
          onBlur={handleBlur}
          error={errors.email}
          required
          onChange={(e, data) => handleChange("email", data.value)}
          label="E-mail"
          autoComplete="username"
          type="email"
        />
        <Form.Input
          defaultValue={data.senha}
          onBlur={handleBlur}
          error={errors.senha}
          required
          onChange={(e, data) => handleChange("senha", data.value)}
          label="Senha"
          autoComplete="current-password"
          type="password"
        />
        <Button type="submit" color="blue">
          Acessar
        </Button>
      </Form>
    </>
  );
}

export default Login;
