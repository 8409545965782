import { useState } from 'react';
import {
  Button, Icon, Modal, Table, Label,
} from 'semantic-ui-react';

function ButtonComponent({ membros, onClick }) {
  const qtd = membros?.length || 0;
  const qtdSocios = membros?.filter((m) => m.eh_socio)?.length || 0;
  if (qtd === 0) return <Button disabled>nenhum membro</Button>;
  return (
    <Button onClick={onClick}>
      {qtd}
      {' '}
      membros /
      {' '}
      {qtdSocios}
      {' '}
      sócios
    </Button>
  );
}

function MembrosModal({ membros }) {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<ButtonComponent membros={membros} />}
    >
      <Modal.Header>Membros da CIPA</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          <Table unstackable celled collapsing className="dnxTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Cargo</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {membros.map((membro) => (
                <Table.Row key={membro.seq}>
                  <Table.Cell>
                    <Label color={membro?.eh_socio ? 'green' : 'red'}>
                      {membro?.eh_socio ? 'SÓCIO' : 'NÃO SÓCIO'}
                    </Label>
                  </Table.Cell>
                  <Table.Cell data-label="Nome">{membro?._embedded?.pefi?.nome}</Table.Cell>
                  <Table.Cell data-label="Cargo ">{membro?._embedded?.cargo?.descricao}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Fechar
          {' '}
          <Icon name="chevron right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default MembrosModal;
