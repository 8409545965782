import Plot from "react-plotly.js";
import { Dimmer, Loader, Menu } from "semantic-ui-react";

import useApiPangeo from "../../../hooks/useApiPangeo";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function GraficoAcordosAno() {
  const { data, isLoading, getAcordoPorAno } = useApiPangeo();
  const [activeItem, setActiveItem] = useState(0);
  const usuario = useSelector((state) => state.login.usuario);

  useEffect(() => {
    if (activeItem === 0) {
      getAcordoPorAno();
    }
    if ([4, 5, 6].includes(activeItem)) {
      getAcordoPorAno({locaacor: activeItem});
    }
  }, [getAcordoPorAno, activeItem]);

  const getArrayValoresInData = (campo) => {
    return data?.["acordos-por-ano"]?.map((r) => `${r[campo]}`.trim());
  };

  const selecionaRegional = () => {
    return usuario?.demais_dados?.recursos
      .filter((recurso) => recurso === "intranetBgp.*").length > 0
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Plot
        data={[
          {
            type: "bar",
            y: getArrayValoresInData("quantidade"),
            x: getArrayValoresInData("ano"),
            marker: {
              color: "rgb(55, 83, 109)",
            },
            text: getArrayValoresInData("quantidade")?.map(String),
            textposition: "auto",
          },
        ]}
        config={{
          modeBarButtonsToAdd: [],
          modeBarButtonsToRemove: [
            "pan2d",
            "select2d",
            "lasso2d",
            "resetScale2d",
            "zoomOut",
            "zoom",
            "zoomIn",
            "autoScale",
          ],
          responsive: true,
          staticPlot: true,
        }}
        layout={{
          title: {
            text: `<b>ACORDOS DIVERSOS${activeItem > 0 ? " DA REGIONAL" : ""}</b>`,
            font: {
              family: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              size: 17,
            },
          },
          xaxis: {
            tickmode: 'linear',
            tickangle: 0,
          },
          margin: { t: 30, b: 15, l: 20, r: 20 },
        }}
        style={{ width: "100%", height: "120px" }}
      />
      {selecionaRegional() ? <Menu size="mini">
        <Menu.Item
          name="TOTAL"
          active={activeItem === 0}
          onClick={() => setActiveItem(0)}
        />
        <Menu.Item
          name="GUARULHOS/SP"
          active={activeItem === 4}
          onClick={() => setActiveItem(4)}
        />
        <Menu.Item
          name="SAO MIGUEL"
          active={activeItem === 6}
          onClick={() => setActiveItem(6)}
        />
        <Menu.Item
          name="STO ANDRE"
          active={activeItem === 5}
          onClick={() => setActiveItem(5)}
        />
      </Menu> : null}
      
    </>
  );
}

export default GraficoAcordosAno;
