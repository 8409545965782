import { Header, Menu, Segment, Table, Dimmer, Loader } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import GraficoDebitos from './GraficoDebitos';
import _ from 'lodash';
import useApiPangeo from "../../../hooks/useApiPangeo";
import CRTabela from './CRTabela';


function ViewDebitosPrincipais() {
  const { getPromisse } = useApiPangeo();
  const [isLoading, setIsLoading] = useState(false);
  const [regionalSelecionada, setRegionalSelecionada] = useState(2);

  const [dadosRequest, setDadosRequest] = useState({});
  const [dadosPorEmpresa, setDadosPorEmpresa] = useState([]);
  const [dados, setDados] = useState([]);

  useEffect(() => {
    if (_.isEmpty(dadosRequest)) {
      setIsLoading(true);
      getPromisse("/totalizacao/debito/debitos-principais").then((res) => {      
          if (res.data) {
            setDadosRequest(res.data)
          }
          setIsLoading(false);
      });
    } else {
      let arr = dadosRequest["debitos-principais"];
      setDados(Object.keys(arr).map((key) => arr[key]));
      if (regionalSelecionada > 0) {
        setDadosPorEmpresa((arr[regionalSelecionada]?.lista) || []);
      }
    }
  }, [getPromisse, dadosRequest, regionalSelecionada]);

  return (
    <div>
        <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Segment>
          <GraficoDebitos dados={dados} />
        </Segment>
        <Segment>
          <Menu size="mini">
            <Menu.Item
              name="GUARULHOS/SP"
              active={regionalSelecionada === 2}
              onClick={() => setRegionalSelecionada(2)}
            />
            <Menu.Item
              name="SAO MIGUEL"
              active={regionalSelecionada === 5}
              onClick={() => setRegionalSelecionada(5)}
            />
            <Menu.Item
              name="STO ANDRE"
              active={regionalSelecionada === 3}
              onClick={() => setRegionalSelecionada(3)}
            />
          </Menu>
          <CRTabela crs={dadosPorEmpresa} />
        </Segment>
    </div>
  );
}

export default ViewDebitosPrincipais;
