import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Radio,
  Input,
  Dimmer,
  Form,
  Header,
  Loader,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react';
import useApiPangeo from '../../hooks/useApiPangeo';
import LocaAcorText from '../../components/LocaAcorText';
import ImagensModal from '../../components/ImagensModal';
import ValorPlr from './components/ValorPlr';
import ParcelasModal from './components/ParcelasModal';
import Moment from 'react-moment';

function encodeValor(anyVal) {
  if (Array.isArray(anyVal)) {
    return anyVal.join(',');
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => `${k}=${encodeValor(object[k])}`)
    .join('&');
}

function AcordosPlr() {
  const { search } = useLocation();
  const { data, isLoading, getAcordoPlr } = useApiPangeo();
  const [page, setPage] = useState(1);
  const [filtroNomeEmpresa, setFiltroNomeEmpresa] = useState(null);
  const navigate = useNavigate();
  const { locaacorId } = useParams();

  const [anoAtual, setAnoAtual] = useState(2024);
  const [tipoApuracao, setTipoApuracao] = useState('vigencia');

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const ano = searchParams.get('ano');
    const tpApuracao = searchParams.get('tipoApuracao');
    if (ano) {
      setAnoAtual(ano);
    }
    if (tpApuracao) {
      setTipoApuracao(tpApuracao);
    }
    if (tpApuracao && ano) {
      const filtros = {
        regi: locaacorId,
        nomeEmpresa: filtroNomeEmpresa,
      };

      let campoAno = 'ano';
      if (tpApuracao === 'assinatura') {
        campoAno = 'anoAssinatura';
      }

      filtros[campoAno] = ano;
  
      filtros.limit = 10;
      filtros.page = page;

      getAcordoPlr(filtros);
    }
  }, [getAcordoPlr, page, search, locaacorId, filtroNomeEmpresa]);

  const changeFiltro = (searchNovo = null) => {
    navigate({
      pathname: `/acordos-plr/regional/${locaacorId}`,
      search: `?${searchNovo || ''}`,
    });
  };

  const changeAnoFiltro = (e, data) => {
    const { value } = data;

    changeFiltro(encodeUrl({ ano: value, tipoApuracao }));
  };

  const changeTipoApuracaoFiltro = (e, data) => {
    const { value } = data;

    changeFiltro(encodeUrl({ tipoApuracao: value, ano: anoAtual }));
  };

  const optionsApuracao = [
    {key: 'a', text: 'por data da assinatura', value: 'assinatura'},
    {key: 'v', text: 'por início da vigência', value: 'vigencia'}
  ];

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: 'left' }}>
          Acordos de PLR - <LocaAcorText locaId={locaacorId} />
        </Header>
        <Breadcrumb style={{ float: 'right' }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section to={`/acordos-plr?ano=${anoAtual}&tipoApuracao=vigencia`} link as={Link}>
            Acordos de PLR por regional
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            Acordos de PLR - <LocaAcorText locaId={locaacorId} />
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form>
        <Form.Group>
          <Form.Input label="Ano">
            <div className="inline-checkbox">
              {[2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018].map((ano) => (
                <Radio
                  label={ano}
                  value={ano}
                  key={ano}
                  checked={parseInt(anoAtual) === ano}
                  onChange={changeAnoFiltro}
                />
              ))}
            </div>
          </Form.Input>
          <Form.Input label="Nome da empresa">
            <Input icon="search" placeholder="Buscar..." onChange={(e) => setFiltroNomeEmpresa(e.target.value)} />
          </Form.Input>
        </Form.Group>
      </Form>

      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <Table unstackable celled collapsing className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Unidade</Table.HeaderCell>
              <Table.HeaderCell>Vigência</Table.HeaderCell>
              <Table.HeaderCell>Assinatura</Table.HeaderCell>
              <Table.HeaderCell>Trabalhadores</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Parcelas</Table.HeaderCell>
              <Table.HeaderCell>Documentos</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {Array.isArray(data?._embedded?.['acordo-plr']) && data?._embedded?.['acordo-plr']?.map((acordo) => (
              <Table.Row key={`${acordo?.id}`}>
                <Table.Cell data-label="Unidade">
                  <Header as="h4" image>
                    <Header.Content>{acordo?._embedded?.unidade?.nome}</Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell data-label="Vigência">
                  <Moment format="DD/MM/YYYY" date={acordo?.dataInicioVigencia?.date} />
                  {' - '}
                  <Moment format="DD/MM/YYYY" date={acordo?.dataFimVigencia?.date} />
                </Table.Cell>
                <Table.Cell data-label="Assinatura">
                  <Moment format="DD/MM/YYYY" date={acordo?.dataAssinatura?.date} />
                </Table.Cell>
                <Table.Cell data-label="Trabalhadores">
                  {new Intl.NumberFormat().format(acordo?.numeroTrabalhadores)}
                  </Table.Cell>
                <Table.Cell data-label="Descrição">{acordo?.descricao || '-'}</Table.Cell>
                <Table.Cell>
                  <ParcelasModal parcelas={acordo?.parcelas} />
                </Table.Cell>
                <Table.Cell>
                  <ImagensModal imagens={(acordo?._embedded?.imagens ?? [])} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell colSpan="6">
                Total de acordos de PLR:
                {' '}
                {new Intl.NumberFormat().format(data?.total_items || 0)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Pagination
          activePage={page}
          style={{ marginTop: '1em' }}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          totalPages={'page_count' in data ? data.page_count : 0}
        />
      </Segment>
    </div>
  );
}

export default AcordosPlr;
