import { useEffect, useState } from "react";
import {
  Dimmer,
  Button,
  Header,
  Loader,
  Segment,
  Table,
  Form,
  Grid,
  Checkbox,
  Breadcrumb,
  Icon,
} from "semantic-ui-react";
import useApiPangeo from "../../hooks/useApiPangeo";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ListaPedidoVisita from "./ListaPedidoVisita";

function encodeValor(anyVal) {
  if (Array.isArray(anyVal)) {
    return anyVal.join(",");
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => `${k}=${encodeValor(object[k])}`)
    .join("&");
}

function PedidosVisita({ tipo }) {
  const [status, setStatus] = useState();
  const [subregiSelecionada, setSubregiSelecionada] = useState();
  const { agrupador } = useParams();
  const { search } = useLocation();
  const { data, isLoading, getPedivisi } = useApiPangeo();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setStatus(searchParams.get("status") || "");
    getPedivisi({ tipo: tipo, status: searchParams.get("status") });
  }, [getPedivisi, search, tipo]);

  const changeFiltro = (searchNovo = null) => {
    navigate({
      pathname: `/${tipo === "A" ? "acidentes" : "denuncias"}/${agrupador}`,
      search: `?${searchNovo || ""}`,
    });
  };

  const selecionaRegional = (regiSubregi) => {
    setSubregiSelecionada(regiSubregi);
  };

  const changeStatusFiltro = (e, data) => {
    const { value, checked } = data;

    let newStatus = status?.split(",") || [];

    if (checked) {
      newStatus.push(value);
    } else {
      newStatus = newStatus.filter((s) => s !== value);
    }

    changeFiltro(encodeUrl({ status: newStatus }));
  };

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: "left" }}>
          {tipo === "A" ? "Acidentes" : "Denúncias"} por regional
        </Header>
        <Breadcrumb style={{ float: "right" }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            {tipo === "A" ? "Acidentes" : "Denúncias"}
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form>
        <Form.Group>
          <Form.Input label="Status">
            <div className="inline-checkbox">
              <Checkbox
                label="Pendente/Não iniciado"
                value="D"
                checked={status?.includes("D")}
                onChange={changeStatusFiltro}
              />
              <Checkbox
                label="Em andamento"
                value="V"
                checked={status?.includes("V")}
                onChange={changeStatusFiltro}
              />
              <Checkbox
                label="Finalizado"
                value="E"
                checked={status?.includes("E")}
                onChange={changeStatusFiltro}
              />
            </div>
          </Form.Input>
        </Form.Group>
      </Form>

      <Grid>
        <Grid.Row stretched>
          <Grid.Column mobile={16} computer={6}>
            <Segment className="tabela-com-detalhes">
              <Dimmer active={isLoading} inverted>
                <Loader>Aguarde ...</Loader>
              </Dimmer>
              <Table basic="very" unstackable celled collapsing className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Regional</Table.HeaderCell>
                    <Table.HeaderCell>
                      Qtd. {tipo === "A" ? "acidentes" : "denúncias"}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {data?.items?.map((regional) => (
                    <Table.Row key={`${regional.regi}.${regional.subregi}`}>
                      <Table.Cell data-label="Regional">
                        <Header as="h4" image>
                          <Header.Content>
                            <Button
                              icon
                              circular
                              onClick={() =>
                                selecionaRegional(
                                  `${regional.regi}.${regional.subregi}`
                                )
                              }
                              positive={
                                `${regional.regi}.${regional.subregi}` ===
                                subregiSelecionada
                              }
                            >
                              <Icon
                                name={
                                  `${regional.regi}.${regional.subregi}` ===
                                  subregiSelecionada
                                    ? "check"
                                    : "plus"
                                }
                              />
                            </Button>
                            {regional.descricao}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell data-label="Quantidade">{regional.pedidos}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {data?.items?.reduce(
                        (accumulator, regi) => accumulator + regi.pedidos,
                        0
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </Grid.Column>
          <Grid.Column mobile={16} computer={10}>
            {subregiSelecionada ? (
              <ListaPedidoVisita
                subregi={subregiSelecionada}
                tipo={tipo}
                statusList={status}
              />
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default PedidosVisita;
