import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { remove } from '../features/Login/loginSlice';
import { instanceAxios as instanceAxiosPangeo } from './useApiPangeo';
import { instanceAxios as instanceAxiosComuInte } from './useApiComunicacaoInterna';

const useAxiosInterceptor = () => {
  const dispatch = useDispatch();
  const [, , removeCookie] = useCookies(['name']);

  instanceAxiosPangeo.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response.status === 401) {
        const options = {
          path: '/',
          sameSite: 'lax',
          secure: true,
          domain: '.sintrabor.org.br',
        };
        removeCookie('authtoken', options);
        dispatch(remove());
      }
    }
  );

  instanceAxiosComuInte.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response.status === 401) {
        const options = {
          path: '/',
          sameSite: 'lax',
          secure: true,
          domain: '.sintrabor.org.br',
        };
        removeCookie('authtoken', options);
        dispatch(remove());
      }
    }
  );

  return null;
};

export default useAxiosInterceptor;
