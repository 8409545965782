import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Dimmer,
  Header,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import useApiPangeo from "../../hooks/useApiPangeo";
import Moment from "react-moment";

function NegociacoesEmAndamento() {
  const { search } = useLocation();
  const { data, isLoading, getNegociacoesEmAndamento } = useApiPangeo();

  useEffect(() => {
    getNegociacoesEmAndamento();
  }, [getNegociacoesEmAndamento, search]);

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: "left" }}>
          Negociações em andamento
        </Header>
        <Breadcrumb style={{ float: "right" }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Negociações em andamento</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <Table unstackable celled collapsing className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Empresa</Table.HeaderCell>
              <Table.HeaderCell>Regional</Table.HeaderCell>
              <Table.HeaderCell>Vigência</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {! isLoading ? data?._embedded?.['negociacao']?.map((row) => {
              return <Table.Row key={row.id}>
              <Table.Cell>
                {row._embedded.tipoAssembleia.descricao}
              </Table.Cell>
              <Table.Cell>({row._embedded.unidade.peju}-{row._embedded.unidade.seq}) {row._embedded.unidade.nome}</Table.Cell>
              <Table.Cell>{row._embedded.unidade.subregi.descricao}</Table.Cell>
              <Table.Cell>
                <Moment
                  format="DD/MM/YYYY"
                  date={row?.dataInicioVigencia?.date}
                /> - <Moment
                format="DD/MM/YYYY"
                date={row?.dataFimVigencia?.date}
              />
              </Table.Cell>
            </Table.Row>
            }) : null}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}

export default NegociacoesEmAndamento;
