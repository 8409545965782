import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Radio,
  Dimmer,
  Form,
  Header,
  Loader,
  Pagination,
  Segment,
  Table,
  Input,
} from 'semantic-ui-react';
import Moment from 'react-moment';
import useApiPangeo from '../../hooks/useApiPangeo';
import LocaAcorText from '../../components/LocaAcorText';
import ImagensModal from '../../components/ImagensModal';

function encodeValor(anyVal) {
  if (Array.isArray(anyVal)) {
    return anyVal.join(',');
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => `${k}=${encodeValor(object[k])}`)
    .join('&');
}

function AcordosPlr() {
  const { search } = useLocation();
  const { data, isLoading, getAcordo } = useApiPangeo();
  const [page, setPage] = useState(1);
  const [filtroNomeEmpresa, setFiltroNomeEmpresa] = useState(null);
  const navigate = useNavigate();
  const { locaacorId } = useParams();

  const [anoAtual, setAnoAtual] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    setAnoAtual(searchParams.get('ano') || '');

    const filtros = {
      locaacor: locaacorId,
      nomeEmpresa: filtroNomeEmpresa,
      ano: searchParams.get('ano')
    };

    filtros.limit = 10;
    filtros.page = page;

    getAcordo(filtros);
  }, [getAcordo, page, search, locaacorId, filtroNomeEmpresa]);

  const changeFiltro = (searchNovo = null) => {
    navigate({
      pathname: `/acordos/regional/${locaacorId}`,
      search: `?${searchNovo || ''}`,
    });
  };

  const changeAnoFiltro = (e, dados) => {
    const { value } = dados;

    changeFiltro(encodeUrl({ ano: value }));
  };

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: 'left' }}>
          Acordos diversos - <LocaAcorText locaId={locaacorId} />
        </Header>
        <Breadcrumb style={{ float: 'right' }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section to={`/acordos?ano=${anoAtual}`} link as={Link}>
          Acordos diversos por regional
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Acordos - <LocaAcorText locaId={locaacorId} /></Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form>
        <Form.Group>
          <Form.Input label="Ano">
            <div className="inline-checkbox">
              {[2024, 2023, 2022, 2021, 2020, 2019, 2018].map((ano) => (
                <Radio
                  label={ano}
                  value={ano}
                  key={ano}
                  checked={parseInt(anoAtual) === ano}
                  onChange={changeAnoFiltro}
                />
              ))}
            </div>
          </Form.Input>
          <Form.Input label="Nome da empresa">
            <Input icon="search" placeholder="Buscar..." onChange={(e) => setFiltroNomeEmpresa(e.target.value)} />
          </Form.Input>
        </Form.Group>
      </Form>

      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <Table unstackable celled collapsing className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Título</Table.HeaderCell>
              <Table.HeaderCell>Empresa</Table.HeaderCell>
              <Table.HeaderCell>Local do acordo</Table.HeaderCell>
              <Table.HeaderCell>Período</Table.HeaderCell>
              <Table.HeaderCell>Documentos</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data?._embedded?.['acordo-outro']?.map((acordo) => (
              <Table.Row key={`${acordo?.id}`}>
                <Table.Cell data-label="Título">
                  <Header as="h4" image>
                    <Header.Content>{acordo.titulo}</Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell data-label="Empresa">{acordo?._embedded?.empresa?.nome}</Table.Cell>
                <Table.Cell data-label="Local do acordo"><LocaAcorText locaId={acordo?.locaacor} /></Table.Cell>
                <Table.Cell data-label="Período">
                  <Moment format="DD/MM/YYYY" date={acordo?.dtinic?.date} />
                  {' - '}
                  <Moment format="DD/MM/YYYY" date={acordo?.dtfim?.date} />
                </Table.Cell>
                <Table.Cell>
                  <ImagensModal imagens={acordo?._embedded?.imagens} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell colSpan="4">
                Total de acordos:
                {' '}
                {new Intl.NumberFormat().format(data?.total_items || 0)}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Pagination
          activePage={page}
          style={{ marginTop: '1em' }}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          totalPages={'page_count' in data ? data.page_count : 0}
        />
      </Segment>
    </div>
  );
}

export default AcordosPlr;
