import { useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { update, remove } from '../features/Login/loginSlice';

export const instanceAxios = axios.create({
  baseURL: 'https://puts.api.pandora.com.br',
  timeout: 10000,
  headers: { Authorization: 'Bearer gpTGr-y161I21QjB7fJ7w' },
});

const useApiPuts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [, setCookie] = useCookies(['name']);

  const loginToken = useSelector((state) => state.login.token);

  const loginAction = useCallback(
    (loginData) => {
      setIsLoading(true);
      instanceAxios
        .post('/auth/email-senha', loginData)
        .then((response) => {
          const options = {
            path: '/',
            sameSite: 'lax',
            secure: true,
            domain: '.sintrabor.org.br',
          };
          console.log(response.data.token);
          
          setCookie('authtoken', response.data.token, options);
          dispatch(update(response.data));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [dispatch, setCookie]
  );

  const alterarSenhaAction = useCallback(
    (userData) => new Promise((resolve, reject) => {
      instanceAxios
        .post('/usuario/metodo-login/email-senha', userData, {
          headers: { Authorization: `Bearer ${loginToken}` },
        })
        .then(() => {
          dispatch(remove());
          resolve();
        })
        .catch((error) => reject(error));
    }),
    [loginToken, dispatch]
  );

  return { isLoading, loginAction, alterarSenhaAction };
};

export default useApiPuts;
