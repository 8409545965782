import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Dimmer,
  Header,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import useApiPangeo from "../../hooks/useApiPangeo";

function Socios() {
  const { search } = useLocation();
  const { data, titulos, isLoading, getSituacaoPessfisiPorRegional } = useApiPangeo();

  useEffect(() => {
    getSituacaoPessfisiPorRegional();
  }, [getSituacaoPessfisiPorRegional, search]);

  const somaPorRegi = (regi, dados) => {
    if (! dados) return;
    let soma = 0;
    Object.keys(dados).forEach((situ) => {
      soma += dados[situ]['porSubsede'][regi];
      if (isNaN(soma)) {
        console.log(dados);
        
      }
    });

    return soma;
  }

  return (
    <div>
      <div className="clearfix">
        <Header as="h3" style={{ float: "left" }}>
          Sócios por regional
        </Header>
        <Breadcrumb style={{ float: "right" }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Sócios por regional</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>Aguarde ...</Loader>
        </Dimmer>
        <Table unstackable celled collapsing className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Situação</Table.HeaderCell>
              {titulos.map((regional, idx) => (
                <Table.HeaderCell key={idx}>{regional}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row active>
              <Table.Cell style={{fontWeight:"bold"}}>ATIVO POLITICAMENTE</Table.Cell>
              {[2,3,5].map((regi) => (
                <Table.Cell key={regi} style={{fontWeight:"bold"}}>{new Intl.NumberFormat().format(somaPorRegi(regi, data.ativpoli) || 0)}</Table.Cell>
              ))}
            </Table.Row>
            {Array.isArray(data.ativpoli) && data.ativpoli.map((row, situ) => (
              <Table.Row key={situ}>
                <Table.Cell data-label="Situação">
                  <Header as="h4" image>
                    <Header.Content>{row.descricao}</Header.Content>
                  </Header>
                </Table.Cell>
                {Object.keys(row.porSubsede).map((regi, idx) => (
                  <Table.Cell key={idx} data-label={titulos[idx]}>{new Intl.NumberFormat().format(row.porSubsede[regi] || 0)}</Table.Cell>
                ))}
              </Table.Row>
            ))}
            <Table.Row active>
              <Table.Cell style={{fontWeight:"bold"}}>SÓCIOS ESTATUTÁRIOS</Table.Cell>
              {[2,3,5].map((regi) => (
                <Table.Cell key={regi} style={{fontWeight:"bold"}}>{new Intl.NumberFormat().format(somaPorRegi(regi, data.naoAtivpoli) || 0)}</Table.Cell>
              ))}
            </Table.Row>
            {Array.isArray(data.naoAtivpoli) && data.naoAtivpoli.map((row, situ) => (
              <Table.Row key={situ}>
                <Table.Cell data-label="Situação">
                  <Header as="h4" image>
                    <Header.Content>{row.descricao}</Header.Content>
                  </Header>
                </Table.Cell>
                {Object.keys(row.porSubsede).map((regi, idx) => (
                  <Table.Cell key={idx} data-label={titulos[idx]}>{new Intl.NumberFormat().format(row.porSubsede[regi] || 0)}</Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  );
}

export default Socios;
