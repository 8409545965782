import { Label } from 'semantic-ui-react';

const statusTraduzido = {
  D: 'Pendente/Não iniciado',
  V: 'Em andamento',
  E: 'Finalizado',
};

const statusCor = {
  D: 'orange',
  V: 'blue',
  E: 'gray',
};

const traduzir = (statusChar) => (Object.keys(statusTraduzido).includes(statusChar)
  ? statusTraduzido[statusChar]
  : statusChar);

function StatusPedivisiText({ statusChar, asLabel }) {
  if (!asLabel) {
    return traduzir(statusChar);
  }
  return <Label color={statusCor[statusChar]}>{traduzir(statusChar)}</Label>;
}

StatusPedivisiText.defaultProps = {
  asLabel: false,
  color: null,
};

export default StatusPedivisiText;
