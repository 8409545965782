import { useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const instanceAxios = axios.create({
  baseURL: 'https://sist.sintrabor.org.br/den/link/api',
  timeout: 100000,
});

const useApiComunicacaoInterna = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const loginToken = useSelector((state) => state.login.token);

  const getLogin = useCallback(
    () => {
      const options = { headers: { Authorization: `Bearer ${loginToken}` } };
      setIsLoading(true);
      instanceAxios
        .get('/login', options)
        .then((response) => {
          setIsLoading(false);
          setData(response.data);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    },
    [loginToken]
  );

  return {
    isLoading,
    isError,
    data,
    getLogin,
  };
};

export default useApiComunicacaoInterna;
