import { Label } from 'semantic-ui-react';

const statusTraduzido = {
  A: 'Agendada',
  R: 'Realizada',
};

const statusCor = {
  A: 'blue',
  R: 'green',
};

const traduzir = (statusChar) => (Object.keys(statusTraduzido).includes(statusChar)
  ? statusTraduzido[statusChar]
  : statusChar);

function StatusVisitaText({ statusChar, asLabel }) {
  if (!asLabel) {
    return traduzir(statusChar);
  }
  return <Label color={statusCor[statusChar]}>{traduzir(statusChar)}</Label>;
}

StatusVisitaText.defaultProps = {
  asLabel: false,
  color: null,
};

export default StatusVisitaText;
