import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import { ToastContainer } from 'react-toastify';
import { store, persistor } from './app/store';
import Login from './features/Login/Login';
import Dashboard from './features/Dashboard/Dashboard';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import ProtectedRoute from './components/ProtectedRoute';
import PedidosVisita from './features/PedidosVisita/PedidosVisita';
import Layout from './components/Layout';
import Visitas from './features/Visitas/Visitas';
import Socios from './features/Socios/Socios';
import AlterarSenha from './features/AlterarSenha/AlterarSenha';
import Acordos from './features/Acordos/Acordos';
import AcordosPorRegional from './features/Acordos/AcordosPorRegional';
import AcordosPlr from './features/AcordosPlr/AcordosPlr';
import AcordosPlrPorRegional from './features/AcordosPlr/AcordosPlrPorRegional';
import Cipas from './features/Cipas/Cipas';
import 'react-toastify/dist/ReactToastify.css';
import Manuais from './features/Manuais/Manuais';
import * as Sentry from "@sentry/react";
import Financeiro from './features/Financeiro/Financeiro';
import PedidosDesligamento from './features/Socios/PedidosDesligamento';
import NegociacoesEmAndamento from './features/Negociacoes/NegociacoesEmAndamento';

Sentry.init({
  dsn: "https://07657ce147b53047067c59aa2712d341@o994633.ingest.us.sentry.io/4507409023565824",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const container = document.getElementById('root');
const root = createRoot(container);

const router = createHashRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/home',
    element: (
      <ProtectedRoute>
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/manuais',
    element: (
      <ProtectedRoute>
        <Layout>
          <Manuais />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/financeiro',
    element: (
      <ProtectedRoute>
        <Layout>
          <Financeiro />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/alterar-senha',
    element: (
      <ProtectedRoute>
        <Layout>
          <AlterarSenha />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/acidentes/:agrupador',
    element: (
      <ProtectedRoute>
        <Layout>
          <PedidosVisita tipo="A" />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/denuncias/:agrupador',
    element: (
      <ProtectedRoute>
        <Layout>
          <PedidosVisita tipo="D" />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/visitas',
    element: (
      <ProtectedRoute>
        <Layout>
          <Visitas />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/socios',
    element: (
      <ProtectedRoute>
        <Layout>
          <Socios />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/socios/pedidos-desligamento-mes',
    element: (
      <ProtectedRoute>
        <Layout>
          <PedidosDesligamento />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/negociacoes',
    element: (
      <ProtectedRoute>
        <Layout>
          <NegociacoesEmAndamento />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/acordos',
    element: (
      <ProtectedRoute>
        <Layout>
          <AcordosPorRegional />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/acordos/regional/:locaacorId',
    element: (
      <ProtectedRoute>
        <Layout>
          <Acordos />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/acordos-plr',
    element: (
      <ProtectedRoute>
        <Layout>
          <AcordosPlrPorRegional />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/acordos-plr/regional/:locaacorId',
    element: (
      <ProtectedRoute>
        <Layout>
          <AcordosPlr />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/cipas',
    element: (
      <ProtectedRoute>
        <Layout>
          <Cipas />
        </Layout>
      </ProtectedRoute>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
