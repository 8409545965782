import {
  Form,
  Button,
  Dimmer,
  Loader,
  Header,
  Breadcrumb,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import useApiPuts from '../../hooks/useApiPuts';
import useForm from '../../hooks/useForm';

function AlterarSenha() {
  const [isLoading, setIsLoading] = useState(false);
  const { alterarSenhaAction } = useApiPuts();
  const { usuario } = useSelector((state) => state.login);

  const nextWithValidation = () => {
    setIsLoading(true);
    alterarSenhaAction(data)
      .then(() => toast.success('Senha alterada'))
      .finally(() => setIsLoading(false));
  };

  const validations = {
    senha: {
      required: {
        value: true,
        message: 'Informe sua nova senha',
      },
    },
    senha_confirmacao: {
      required: {
        value: true,
        message: 'Confirme sua nova senha',
      },
      equal: {
        field: 'senha',
        message: 'As senhas não são iguais',
      },
    },
  };

  const {
    data, handleChange, handleSubmit, handleBlur, errors,
  } = useForm(
    validations,
    { email: usuario.email },
    nextWithValidation,
  );

  return (
    <div>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <div className="clearfix">
        <Header as="h3" style={{ float: 'left' }}>
          Alterar senha
        </Header>
        <Breadcrumb style={{ float: 'right' }}>
          <Breadcrumb.Section to="/" link as={Link}>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Alterar senha</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <Form className="form-login" onSubmit={handleSubmit}>
        <Form.Input
          defaultValue={data.senha}
          onBlur={handleBlur}
          error={errors.senha}
          required
          id="novaSenha"
          onChange={(e, d) => handleChange('senha', d.value)}
          label="Nova senha"
          autoComplete="current-password"
          type="password"
        />
        <Form.Input
          defaultValue={data.senha_confirmacao}
          onBlur={handleBlur}
          error={errors.senha_confirmacao}
          required
          id="confirmaNovaSenha"
          onChange={(e, dt) => handleChange('senha_confirmacao', dt.value)}
          label="Confirmar nova senha"
          autoComplete="current-password"
          type="password"
        />
        <Button type="submit" color="blue">
          Confirmar alteração
        </Button>
      </Form>
    </div>
  );
}

export default AlterarSenha;
