import { useState } from 'react';

const useForm = (validations, initialValues, onSubmit) => {
  const [data, setData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChangeObject = (dataObject) => {
    setData(dataObject);
  };

  const validate = async (onlyKey = null) => {
    let valid = true;
    const newErrors = {};
    Object.keys(validations).forEach(async (key) => {
      if (onlyKey && onlyKey !== key) return;
      let value = data[key];
      const validation = validations[key];

      if (validation?.filter?.pattern && value) {
        value = value.replace(validation?.filter?.pattern, '');
      }

      if (validation?.required?.value && !value) {
        valid = false;
        newErrors[key] = validation?.required?.message;
      }

      const pattern = validation?.pattern;
      if (pattern?.value && !RegExp(pattern.value).test(value)) {
        valid = false;
        newErrors[key] = pattern.message;
      }
      const notIn = validation?.notIn;
      if (notIn?.value && notIn.value.includes(value)) {
        valid = false;
        newErrors[key] = notIn.message;
      }

      const custom = validation?.custom;
      if (custom?.isValid) {
        let runCustom = true;
        if (custom?.conditions) {
          Object.keys(custom.conditions).then((cond) => {
            if (data[cond] !== custom.conditions[cond]) {
              runCustom = false;
            }
          });
        }
        if (runCustom) {
          let customValid = null;
          if (custom?.async) {
            customValid = await custom.isValid(value);
          } else {
            customValid = custom.isValid(value);
          }
          if (!customValid) {
            valid = false;
            newErrors[key] = custom.message;
          }
        }
      }

      if (validation?.equal && data[validation?.equal?.field] !== value) {
        valid = false;
        newErrors[key] = validation?.equal?.message;
      }
    });
    return { valid, newErrors };
  };

  const handleBlur = async (e, value) => {
    const name = value ? value.name : e.target.name;
    if (!name) {
      return;
    }

    if (validations) {
      setIsLoading(true);
      const { valid, newErrors } = await validate(name);
      setIsLoading(false);
      if (!valid) {
        setErrors({ ...errors, ...newErrors });
        return;
      }
    }
    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validations) {
      setIsLoading(true);
      const { valid, newErrors } = await validate();
      setIsLoading(false);
      if (!valid) {
        setErrors(newErrors);
        return;
      }
    }
    setErrors({});

    if (onSubmit) {
      onSubmit();
    }
  };

  return {
    data,
    handleChange,
    handleChangeObject,
    handleSubmit,
    handleBlur,
    errors,
    isLoading,
  };
};

export default useForm;
