const locaTraduzido = {
  1: 'LAPA/SP/CAIEIRAS/PERUS/FM',
  2: 'GUARULHOS/SP',
  3: 'STO ANDRE /SCS/MAUA/SP/RGS',
  4: 'DIADEMA/SP/SBC',
  5: 'SAO MIGUEL ITAQUA/ARUJA/POA/SP',
  6: 'OUTRAS',
  7: 'MOGI DAS CRUZES',
  8: 'PRAIA GRANDE',
  9: 'JACAREI',
  10: 'SEM REGIONAL',
};

function LocaAcorText({ locaId }) {
    return locaTraduzido[locaId];
}

export default LocaAcorText;
