import Plot from 'react-plotly.js';
import { Dimmer, Loader, Menu } from 'semantic-ui-react';

import { useEffect, useState } from 'react';
import useApiPangeo from '../../../hooks/useApiPangeo';
import { useSelector } from 'react-redux';

function GraficoAcordosPlrAno() {
  const { data, isLoading, getAcordoPlrPorAno, getAcordoPlrPorAnoPorRegional } = useApiPangeo();
  const [activeItem, setActiveItem] = useState(0);
  const usuario = useSelector((state) => state.login.usuario);

  useEffect(() => {
    if (activeItem === 0) {
      getAcordoPlrPorAno();
    }
    if ([2, 3, 5].includes(activeItem)) {
      getAcordoPlrPorAnoPorRegional(activeItem);
    }
  }, [getAcordoPlrPorAno, activeItem]);

  const getArrayValoresInData = (campo) => data?.['acordos-plr-por-ano']
    ?.filter((r) => r.ano > 2020)
    ?.map((r) => `${r[campo]}`.trim());

  const selecionaRegional = () => {
    return usuario?.demais_dados?.recursos
      .filter((recurso) => recurso === "intranetBgp.*").length > 0
  };
  

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Plot
        data={[
          {
            type: 'bar',
            y: getArrayValoresInData('quantidade'),
            x: getArrayValoresInData('ano'),
            marker: {
              color: 'rgb(55, 83, 109)',
            },
            text: getArrayValoresInData('quantidade')?.map(String),
            textposition: 'auto',
          },
        ]}
        config={{
          modeBarButtonsToAdd: [],
          modeBarButtonsToRemove: [
            'pan2d',
            'select2d',
            'lasso2d',
            'resetScale2d',
            'zoomOut',
            'zoom',
            'zoomIn',
            'autoScale',
          ],
          responsive: true,
          staticPlot: true,
        }}
        layout={{
          title: {
            text: `<b>ACORDOS PLR${activeItem > 0 ? " DA REGIONAL" : ""}</b>`,
            font: {
              family: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              size: 17,
            },
          },
          xaxis: {
            tickmode: 'linear',
            tickangle: 0,
          },
          margin: {
            t: 30, b: 15, l: 20, r: 20,
          },
        }}
        style={{ width: '100%', height: '120px' }}
      />
      {selecionaRegional() ? <Menu size="mini">
        <Menu.Item
          name="TOTAL"
          active={activeItem === 0}
          onClick={() => setActiveItem(0)}
        />
        <Menu.Item
          name="GUARULHOS/SP"
          active={activeItem === 2}
          onClick={() => setActiveItem(2)}
        />
        <Menu.Item
          name="SAO MIGUEL"
          active={activeItem === 5}
          onClick={() => setActiveItem(5)}
        />
        <Menu.Item
          name="STO ANDRE"
          active={activeItem === 3}
          onClick={() => setActiveItem(3)}
        />
      </Menu> : null}
      
    </>
  );
}

export default GraficoAcordosPlrAno;
