import Plot from "react-plotly.js";

function GraficoArrecadacao({dados}) {

  return (
    <>
      <Plot
        data={[
          {
            type: "bar",
            y: dados?.map((r) => r.total),
            x: dados?.map((r) => r.mesAno),
            marker: {
              color: "rgb(55, 83, 109)",
            },
            texttemplate: "%{value}",
            text: dados?.map((r) => r.total),
            textposition: "auto",
          },
        ]}
        config={{
          modeBarButtonsToAdd: [],
          modeBarButtonsToRemove: [
            "pan2d",
            "select2d",
            "lasso2d",
            "resetScale2d",
            "zoomOut",
            "zoom",
            "zoomIn",
            "autoScale",
          ],
          responsive: true,
          staticPlot: true,
        }}
        layout={{
          xaxis: {
            tickformat: '%m/%Y',
          },
          yaxis: {
            tickformat: '.2s'
          },
          margin: { t: 15, b: 15, l: 30, r: 20 },
        }}
        style={{ width: "100%", height: "150px" }}
      />
    </>
  );
}

export default GraficoArrecadacao;
