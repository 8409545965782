import Plot from 'react-plotly.js';
import { Dimmer, Loader } from 'semantic-ui-react';

import { useEffect } from 'react';
import useApiPangeo from '../../../hooks/useApiPangeo';

function GraficoCipasPorRegional() {
  const { data, isLoading, getCipasPorRegional } = useApiPangeo();

  useEffect(() => {
    getCipasPorRegional();
  }, [getCipasPorRegional]);

  const resumeDescricao = (descricao) => {
    if (!descricao) return descricao;

    const nomes = {
      'SANTO ANDRE /SCS/MAUA/SP/RGS': 'STO ANDRE',
      'SAO MIGUEL ITAQUA/ARUJA/POA/SP': 'SAO MIGUEL',
      'GUARULHOS/SP': 'GUARULHOS',
    };

    descricao = `${descricao}`.trim();

    if (!Object.keys(nomes).includes(descricao)) return descricao;

    return nomes[descricao];
  };

  const getArrayValoresInData = (campo) => {
    if (!Array.isArray(data)) return [];
    return data?.map((r) => resumeDescricao(r[campo]));
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Plot
        data={[
          {
            type: 'pie',
            values: getArrayValoresInData('total'),
            labels: getArrayValoresInData('descricao'),
            textposition: 'auto',
            textinfo: "percent+value",
            marker: {
              colors: ["#4183c4", "	#4142c4", "	#41c4c4", "#83c441"],
            },
          },
        ]}
        config={{
          responsive: true,
          staticPlot: true,
        }}
        layout={{
          title: {
            text: '<b>CIPAS ATIVAS</b>',
            font: {
              family: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
              size: 17,
            },
          },
          margin: {
            t: 40, b: 30, l: 40, r: 20,
          },
          showlegend: true,
          legend: {"orientation": "h"},
        }}
        style={{ width: '100%', height: '350px' }}
      />
    </>
  );
}

export default GraficoCipasPorRegional;
