import { useState } from 'react';
import Moment from 'react-moment';
import {
  Button, Dimmer, Icon, Loader, Modal, Table,
} from 'semantic-ui-react';
import useApiPangeo from '../hooks/useApiPangeo';

function ButtonComponent({ imagens, onClick }) {
  const qtd = imagens?.length || 0;

  if (qtd === 0) return <Button disabled>nenhum documento</Button>;
  return (
    <Button onClick={onClick}>
      {qtd}
      {' '}
      documentos
    </Button>
  );
}

function ImagensModal({ imagens }) {
  const [open, setOpen] = useState(false);
  const {getImagemPromisse} = useApiPangeo();
  const [isLoading, setIsLoading] = useState(false);

  const baixarImagem = (imagem) => {
    setIsLoading(true);
    getImagemPromisse(`${imagem._links.self.href}/pdf`)
    .then((response) => {
      const obj = new Blob([response.data], { type: 'application/pdf' });
      setIsLoading(false);
      window.open(URL.createObjectURL(obj));
    })
    .catch((e) => {
      console.log(e);
      setIsLoading(false)
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<ButtonComponent imagens={imagens} />}
    >
      <Modal.Header>Documentos</Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          <Dimmer active={isLoading} inverted>
            <Loader>Aguarde ...</Loader>
          </Dimmer>
          <Table unstackable celled collapsing className="dnxTable">
            <Table.Header>.
              <Table.Row>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Data inclusão</Table.HeaderCell>
                <Table.HeaderCell/>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {imagens.map((imagem) => (
                <Table.Row key={imagem.id}>
                  <Table.Cell data-label="Descrição">{imagem?.descricao}</Table.Cell>
                  <Table.Cell data-label="Data inclusão ">
                    <Moment format="DD/MM/YYYY" date={imagem?.dtincl?.date} />
                  </Table.Cell>
                  <Table.Cell>
                    <Button color="blue" onClick={() => baixarImagem(imagem)}>
                        <Icon name="download" />
                        {" "}
                        Baixar
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Fechar
          {' '}
          <Icon name="chevron right" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ImagensModal;
