import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Dimmer, Form, Loader, Radio, Segment } from 'semantic-ui-react';
import useApiPangeo from "../../../hooks/useApiPangeo";
import GraficoArrecadacao from './GraficoArrecadacao';
import moment from 'moment';


function ViewArrecadacao() {
  const { getPromisse } = useApiPangeo();
  const [isLoading, setIsLoading] = useState(false);
  const [dados, setDados] = useState([]);
  const [anoAtual, setAnoAtual] = useState(moment().format('Y'));

  function mudaAno(ano) {
    setAnoAtual(ano);
  }

  useEffect(() => {
    setIsLoading(true);
    getPromisse(`/totalizacao/arrecadacao/por-mes?ano=${anoAtual}`).then((res) => {
      let dataTratado = [];
      if (res.data) {
        Object.entries(res.data["arrecadacao-por-mes"]).forEach(([key, value]) => {
          dataTratado.push({
            total: value.total,
            mesAno: moment(`${key}01`, 'YYYYMMDD').format('YYYY-MM-DD'),
          });

        })
      }
      setDados(dataTratado)
      setIsLoading(false);
    });
  }, [getPromisse, anoAtual]);

  return (
    <div>
      <Dimmer active={isLoading} inverted>
        <Loader>Aguarde ...</Loader>
      </Dimmer>
      <Form>
        <Form.Group>
          <Form.Input label="Ano">
            <div className="inline-checkbox">
              {[2024, 2023, 2022, 2021, 2020, 2019, 2018].map((ano) => (
                <Radio
                  label={ano}
                  value={ano}
                  key={ano}
                  checked={parseInt(anoAtual) === ano}
                  onChange={() => mudaAno(ano)}
                />
              ))}
            </div>
          </Form.Input>
        </Form.Group>
      </Form>
      <Segment>
        <GraficoArrecadacao dados={dados} />
      </Segment>
    </div>
  );
}

export default ViewArrecadacao;
