import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const login = useSelector((state) => state.login);

  useEffect(() => {
    if (login.token) {
      const jwtPayload = JSON.parse(window.atob(login.token.split('.')[1]));
      if (Date.now() >= jwtPayload.exp * 1000) {
        setIsLoggedIn(false);
        return navigate('/login');
      }
    }
    if (!login.usuario?.id) {
      setIsLoggedIn(false);
      return navigate('/login');
    }
    setIsLoggedIn(true);
  }, [navigate, login.usuario]);

  return isLoggedIn && children;
}

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
