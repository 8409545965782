import Plot from "react-plotly.js";

function GraficoDebitos({dados}) {
  return (
    <>
      <Plot
        data={[
          {
            type: "bar",
            y: dados?.map((r) => r.quantidade),
            x: dados?.map((r) => `${r.entidade.descricao}`.trim()),
            marker: {
              color: "rgb(55, 83, 109)",
            },
            text: dados?.map((r) => r.quantidade),
            textposition: "auto",
          },
        ]}
        config={{
          modeBarButtonsToAdd: [],
          modeBarButtonsToRemove: [
            "pan2d",
            "select2d",
            "lasso2d",
            "resetScale2d",
            "zoomOut",
            "zoom",
            "zoomIn",
            "autoScale",
          ],
          responsive: true,
          staticPlot: true,
        }}
        layout={{
          xaxis: {
            tickmode: 'linear',
            tickangle: 0,
          },
          margin: { t: 15, b: 15, l: 20, r: 20 },
        }}
        style={{ width: "100%", height: "150px" }}
      />
    </>
  );
}

export default GraficoDebitos;
