import { useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const instanceAxios = axios.create({
  //baseURL: 'http://localhost:8000/link/api',
  baseURL: 'https://sist.sintrabor.org.br/pangeo/link/api',
  timeout: 1000000,
});

function encodeValor(anyVal) {
  if (typeof anyVal === 'object' && anyVal !== null) {
    return Object.keys(anyVal).join(',');
  }
  return anyVal;
}

function encodeUrl(object) {
  return Object.keys(object)
    .map((k) => object[k] && `${k}=${encodeValor(object[k])}`)
    .join('&');
}

const useApiPangeo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState({});
  const loginToken = useSelector((state) => state.login.token);

  const [titulos, setTitulos] = useState([]);

  const getPedivisi = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/pedivisi/agrupado/subregional?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getResumo = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/totalizacao/resumo', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getPedidosDesligamento = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/socio?pedidosDesligamentoMes=true&limit=-1', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getPessfisiMovimentacao = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/totalizacao/pessfisi/movimentacao', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getPessfisiMovimentacaoPorRegional = useCallback((regionalId) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/pessfisi/movimentacao/por-regional/${regionalId}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getCipasPorRegional = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/totalizacao/cipa/agrupado/por-regional', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getNegociacoesEmAndamento = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/negociacao?apenasAbertos=true&limit=-1', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getSituacaoPessfisiPorRegional = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/totalizacao/pessfisi/situacao/por-regional', options)
      // eslint-disable-next-line consistent-return
      .then((response) => {
        const dataArray = response.data;

        if (!Array.isArray(dataArray) || dataArray.length === 0) return [];
        const dadosTratados = {
          ativpoli: [],
          naoAtivpoli: [],
        };

        const ths = [];
        let listaDadosAtual = [];

        dataArray.forEach((row) => {
          if (row.ativpoli) {
            listaDadosAtual = dadosTratados.ativpoli;
          } else {
            listaDadosAtual = dadosTratados.naoAtivpoli;
          }

          if (!(row.situ in listaDadosAtual)) {
            listaDadosAtual[row.situ] = {
              porSubsede: {2:0, 3:0, 5:0},
              ativpoli: row.ativpoli,
              descricao: row.situacao,
            };
          }
          listaDadosAtual[row.situ].porSubsede[row.regi] = row.quantidade;

          if (ths.length < 3) {
            ths.push(row.subregional.trim());
          }

          if (row.ativpoli) {
            dadosTratados.ativpoli = listaDadosAtual;
          } else {
            dadosTratados.naoAtivpoli = listaDadosAtual;
          }
        });

        setIsLoading(false);
        setTitulos(ths);
        setData(dadosTratados);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getVisita = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/visitas?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPlrPorAno = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/totalizacao/acordo-plr/por-ano', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPlrPorAnoAssinatura = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get('/totalizacao/acordo-plr/por-ano-assinatura', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPlrPorRegional = useCallback((ano, tipoApuracao) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    let campoAno = 'ano';
    if (tipoApuracao === 'assinatura') {
      campoAno = 'anoAssinatura';
    }
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/acordo-plr/por-regional?${campoAno}=${ano}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPlrPorAnoPorRegional = useCallback((regionalId) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/acordo-plr/por-ano?regionalId=${regionalId}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPorAno = useCallback((filtro = {}) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/acordo/por-ano?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPorRegional = useCallback((ano) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/totalizacao/acordo/por-regional?ano=${ano}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getPedivisiList = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/pedivisi?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getVisitaList = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/visita?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordo = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/acordo-outro?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getAcordoPlr = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/acordo-plr?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        console.log(response.data?._embedded?.['acordo-plr']);
        
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getCipa = useCallback((filtro) => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    instanceAxios
      .get(`/peju-unid-orga?${encodeUrl(filtro)}`, options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getLogin = useCallback(() => {
    const options = { headers: { Authorization: `Bearer ${loginToken}` } };
    setIsLoading(true);
    return instanceAxios
      .get('/login', options)
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [loginToken]);

  const getPromisse = useCallback((url) => {
    const options = { 
      headers: { Authorization: `Bearer ${loginToken}` },
    };
    return instanceAxios
      .get(url, options);
  }, [loginToken]);

  const getImagemPromisse = useCallback((url) => {
    const options = { 
      headers: { Authorization: `Bearer ${loginToken}` },
      responseType: 'blob'
    };
    return instanceAxios
      .get(url, options);
  }, [loginToken]);

  return {
    isLoading,
    isError,
    data,
    titulos,
    getPedivisi,
    getResumo,
    getVisita,
    getAcordoPlrPorAno,
    getAcordoPlrPorAnoPorRegional,
    getAcordoPorAno,
    getAcordoPlrPorRegional,
    getAcordoPorRegional,
    getPedivisiList,
    getVisitaList,
    getSituacaoPessfisiPorRegional,
    getCipasPorRegional,
    getPessfisiMovimentacao,
    getPessfisiMovimentacaoPorRegional,
    getAcordo,
    getAcordoPlr,
    getCipa,
    getLogin,
    getImagemPromisse,
    getPromisse,
    getPedidosDesligamento,
    getAcordoPlrPorAnoAssinatura,
    getNegociacoesEmAndamento,
  };
};

export default useApiPangeo;
